import auth, { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
import {
  PermissionRoleView,
  PermissionUserView,
  UserPermissionConfigView,
  UserPermissionEvaluateChartsView,
  UserPermissionEvaluateConfigView,
  UserPermissionEvaluateView,
  UserPermissionFinanceBillView,
  UserPermissionFinanceRecordView,
  UserPermissionInquiryCrawlerView,
  UserPermissionInquiryDataSettingView,
  UserPermissionInquiryDataView,
  UserPermissionLLMModelView,
  UserPermissionMechanismView,
  UserPermissionParameterView,
} from './utils/constants';

export type IRoute = AuthParams & {
  name: string;
  key: string;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
};

export const routes: IRoute[] = [
  {
    name: '欢迎页',
    key: 'welcome',
    requiredPermissions: [],
  },
  {
    name: '机构管理',
    key: 'mechanism',
    requiredPermissions: [UserPermissionMechanismView],
    oneOfPerm: true,
    children: [
      {
        name: '机构列表',
        key: 'mechanism/list',
        requiredPermissions: [UserPermissionMechanismView],
        oneOfPerm: false,
      },
    ],
  },
  {
    name: '用户管理',
    key: 'user',
    requiredPermissions: [PermissionUserView, PermissionRoleView],
    oneOfPerm: true,
    children: [
      {
        name: '用户管理',
        requiredPermissions: [PermissionUserView],
        oneOfPerm: false,
        key: 'user/index',
      },
      {
        name: '角色管理',
        requiredPermissions: [PermissionRoleView],
        oneOfPerm: false,
        key: 'role/index',
      },
    ],
  },
  {
    name: '项目评审',
    key: 'expertReview',
    requiredPermissions: [
      UserPermissionEvaluateView,
      UserPermissionEvaluateConfigView,
      UserPermissionEvaluateChartsView,
    ],
    oneOfPerm: true,
    children: [
      {
        name: '项目申请',
        requiredPermissions: [UserPermissionEvaluateConfigView],
        oneOfPerm: false,
        key: 'expertReview/index',
      },
      {
        name: '评审统计',
        requiredPermissions: [UserPermissionEvaluateChartsView],
        oneOfPerm: false,
        key: 'expertReview/statistics',
      },
      {
        name: '项目评审',
        requiredPermissions: [UserPermissionEvaluateView],
        oneOfPerm: false,
        key: 'expertReview/view',
      },
      {
        name: '评审',
        requiredPermissions: [UserPermissionEvaluateView],
        oneOfPerm: false,
        key: 'projectDetail',
        ignore: true,
      },
    ],
  },
  {
    name: '财务管理',
    key: 'finance',
    requiredPermissions: [
      UserPermissionFinanceBillView,
      UserPermissionFinanceRecordView,
    ],
    oneOfPerm: true,
    children: [
      {
        name: '流水明细',
        requiredPermissions: [UserPermissionFinanceRecordView],
        oneOfPerm: false,
        key: 'finance/index',
      },
      {
        name: '发票申请',
        requiredPermissions: [UserPermissionFinanceBillView],
        oneOfPerm: false,
        key: 'finance/invoice',
      },
    ],
  },
  {
    name: '数据管理',
    key: 'inquiry/data',
    requiredPermissions: [UserPermissionInquiryDataView],
    oneOfPerm: true,
    children: [
      {
        name: '公网数据',
        key: 'inquiry/dataPublic/index',
        requiredPermissions: [UserPermissionInquiryDataView],
        oneOfPerm: false,
      },
      {
        name: '数据详情',
        key: 'inquiry/dataPublic/detail',
        requiredPermissions: [UserPermissionInquiryDataView],
        oneOfPerm: false,
        ignore: true,
      },
    ],
  },
  {
    name: '数据设置',
    key: 'inquiry/setting',
    requiredPermissions: [UserPermissionInquiryDataSettingView],
    oneOfPerm: true,
    children: [
      {
        name: '入库设置',
        key: 'inquiry/setting/difySetting',
        requiredPermissions: [UserPermissionInquiryDataSettingView],
        oneOfPerm: false,
      },
      {
        name: '标准设置',
        key: 'inquiry/setting/data',
        requiredPermissions: [UserPermissionInquiryDataSettingView],
        oneOfPerm: false,
      },
    ],
  },
  {
    name: '采集任务',
    key: 'inquiry/crawler',
    requiredPermissions: [UserPermissionInquiryCrawlerView],
    oneOfPerm: true,
    children: [
      {
        name: '任务列表',
        key: 'inquiry/crawler/index',
        requiredPermissions: [UserPermissionInquiryCrawlerView],
        oneOfPerm: false,
      },
      {
        name: '采集数据',
        key: 'inquiry/crawler/task',
        requiredPermissions: [UserPermissionInquiryCrawlerView],
        oneOfPerm: false,
      },
    ],
  },
  {
    name: '系统设置',
    key: 'setting',
    requiredPermissions: [
      UserPermissionParameterView,
      UserPermissionLLMModelView,
      UserPermissionConfigView,
    ],
    oneOfPerm: true,
    children: [
      {
        name: '计费标准',
        key: 'setting/params',
        requiredPermissions: [UserPermissionParameterView],
        oneOfPerm: false,
      },
      {
        name: '模型管理',
        key: 'setting/models',
        requiredPermissions: [UserPermissionLLMModelView],
        oneOfPerm: false,
      },
      {
        name: '其他设置',
        key: 'setting/setting',
        requiredPermissions: [UserPermissionConfigView],
        oneOfPerm: false,
      },
    ],
  },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

const useRoute = (userPermission): [IRoute[], string] => {
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);

  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
